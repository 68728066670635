import { useEffect, useState } from "react";
import { getSchools } from "../api/lib/schools";
import { getUser, saveUser } from "../api/lib/participants";
import { ClockLoader } from "react-spinners"
import { useNavigate } from "react-router-dom";
import TailwindSelect from "../components/tailwindSelect";
import InputFamilyCode from "../components/inputFamilyCode";

function PageSchueler() {
  const [schoolData, setSchoolData] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("-1");
  const [selectedSchool, setSelectedSchool] = useState("-1");
  const [familyCode, setFamilyCode] = useState("");
  const [familyCodeField, setFamilyCodeField] = useState("");
  const [age, setAge] = useState("0");
  const [surveyStatus, setSurveyStatus] = useState("new");
  const [participantUuid, setParticipantUUID] = useState(crypto.randomUUID());

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();


  useEffect(() => {
    let _participantUuid = localStorage.getItem("uuid_s");
    if(_participantUuid){
      setParticipantUUID(_participantUuid);
    }
    else{
      _participantUuid = participantUuid;
      localStorage.setItem("uuid_s", _participantUuid);
    }

    const _fetchData = async () => {
      setIsLoading(true);
      const sd = await getSchools();
      if(sd.data){
        setSchoolData(sd.data);
        const ud = await getUser('s', _participantUuid);
        setIsLoading(false);
        if(ud.data){
          if(ud.status)
            setSurveyStatus(ud.data.status)
          if(ud.data?.school && ud.data?.school != "null"){
            setSelectedSchool(ud.data.school);
            let tmp = sd.data.find(s => s.id == ud.data.school);
            if(tmp)
              setSelectedDistrict(tmp.filter_one)
          }
          if(ud.data?.familyCode && ud.data?.familyCode != "null"){
            setFamilyCode(ud.data.familyCode);
            setFamilyCodeField(ud.data.familyCode);
          }
          if(ud.data?.age)
            setAge(ud.data.age);
        }        
      }
    }

    _fetchData()
    .catch((error) => {
      console.error(error);
      setIsLoading(false);
    });    

  }, [participantUuid])

  const saveUserData = (d) => {
    d = (!d || !(d instanceof Object) || d?.nativeEvent) ? {} : d;
    const ud = {
      uuid: participantUuid,
      school: selectedSchool,
      familyCode,
      type: 's',
      age,
      ...d
    }
    if(ud.school=="-1" || !ud.age)
      return;    
    setIsLoading(true);
    saveUser(ud)
    .finally(() => {
      setIsLoading(false);
    })
  }

  
  const emptySelect = { value: "-1", content: "-- nichts ausgewählt --" };


  const getDistricts = (schools) => {
    let districts = [emptySelect];
    if (!schools?.length)
      return districts;
    schools.forEach(s => {
      if (s.filter_one && !districts.find(({ value }) => value == s.filter_one))
        districts.push({ value: s.filter_one, content: s.filter_one == "AS" ? "Ausland" : s.land });
    });
    return districts;
  }

  const getSchoolsBySelectedDistrict = (schools) => {
    if (!schools?.length)
      return [emptySelect];
    return [emptySelect].concat(schools.filter(s => {
      return s.filter_one == selectedDistrict;
    })
      .map(s => {
        return { value: s.id, content: s.school }
      }));
  }

  const setDistrict = (d) => {
    setSelectedDistrict(d);
    setSelectedSchool("-1");
  }

  const startSurvey = (uuid) => {
    navigate(`/qr/${uuid}`)
  }

  return (
    <>
      {isLoading && <div className="absolute left-1/2 top-1/2"><ClockLoader color="#36d7b7" /></div>}
      <form>
        <div className="flex flex-col gap-y-4">
          <div>
            <h2 className="mb-4">Bitte wähle Deine Schule aus:</h2>
            <div className="flex flex-wrap gap-4">
              <div className="flex flex-col min-w-64 xs:flex-1 md:flex-initial">
                <TailwindSelect id="school-district" className={"bg-accent1-lighter font-mono font-bold text-sm text-blue-800"} value={selectedDistrict} onChange={v => setDistrict(v.id)} onBlur={saveUserData} options={getDistricts(schoolData).map((d, i) => {return {id: d.value, name: d.content}})} />
                <label className="dsb" htmlFor="school-district">Bundesland</label>
              </div>
              <div className="flex flex-col min-w-72 xs:flex-1">
                <TailwindSelect id="school" className={"bg-accent1-lighter font-mono font-bold text-sm text-blue-800"} value={selectedSchool} onChange={v => setSelectedSchool(v.id)} onBlur={saveUserData} options={getSchoolsBySelectedDistrict(schoolData).map((d, i) => {return {id: d.value, name: d.content}})} />
                <label className="dsb" htmlFor="school">Schule</label>
              </div>
            </div>
          </div>
          <div>
            <h2 className="my-4">Wie alt bist Du?</h2>
            <div className="flex flex-col">
              <input className="dsb bg-accent1-lighter" placeholder="Alter (Jahre)" type="number" min={16} max={30} id={`age`} onChange={(e) => {setAge(e.target.value); saveUserData({age: e.target.value});}} value={age} />              
              <label className="dsb" htmlFor={`age`}>Alter (Jahre)</label>
            </div>
          </div>
          <div>
            <h2 className="my-4">Familien-Code</h2>
            <div className="flex flex-col">
              <InputFamilyCode className={"bg-accent1-lighter"} value={familyCodeField} onChange={v => {setFamilyCode(v); if(v.length == 8) saveUserData({familyCode: v});}} />
            </div>
          </div>          

          <div>
            <h2 className="my-4">Fragebogen:</h2>
            <div className="flex gap-x-2 items-center mb-4 p-4 bg-gray-200">
              <div className="flex-1">
                <button className="dsb w-full" disabled={surveyStatus=="complete" || parseInt(age)==0 || selectedSchool=="-1"} onClick={() => startSurvey(participantUuid)} type="button">
                  <span>Fragebogen </span>
                  {(surveyStatus == "new") && <span>starten</span>}
                  {(surveyStatus == "started") && <span>fortsetzen</span>}
                  {(surveyStatus == "complete") && <span>abgeschlossen</span>}
                </button>
              </div>
            </div>
          </div>          

        </div>
      </form>
    </>
  )
}

export default PageSchueler
