import { Outlet } from "react-router-dom"
import DSBFooter from "../components/footer"
import DSBHeader from "../components/header"

function LayoutRoot() {
  return (
    <>
        <main className="flex flex-col h-screen min-h-screen w-full">
            <DSBHeader />
            <section className="flex-1 overflow-y-auto relative py-8">
                <Outlet />
            </section>
            <DSBFooter />
        </main>
    </>
  )
}

export default LayoutRoot
