import { BrowserRouter as Router, Routes, Route } from "react-router-dom" 
import LayoutRoot from "./layout/root"
import PageLanding from "./views/landing"
import PageEltern from "./views/eltern"
import PageSchueler from "./views/schueler"
import PageSurvey from "./views/survey"
import SurveyLanding from "./views/surveyLanding"
import LayoutSurvey from "./layout/survey"
// import './App.css'

function App() {
  return (
        <Router>
            <Routes>
                <Route path='/' element={<LayoutRoot />} >
                    <Route index element={<PageLanding />} />
                    <Route path="eltern" element={<PageEltern />} />
                    <Route path="start" element={<PageSchueler />} />
                    <Route path="qr/:uuid/:child?" element={<SurveyLanding />} />
                </Route>
                <Route path="survey/" element={<LayoutSurvey />} >
                    <Route path=":uuid/:child?" element={<PageSurvey />} />
                </Route>
            </Routes>
        </Router>
  )
}

export default App
