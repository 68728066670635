import { useEffect, useState } from "react";
import { getSchools, useGetSchools } from "../api/lib/schools";
import { getUser, saveUser } from "../api/lib/participants";
import { ClockLoader } from "react-spinners"
import { useNavigate } from "react-router-dom";
import TailwindSelect from "../components/tailwindSelect";
import InputFamilyCode from "../components/inputFamilyCode";

function PageEltern() {

  const [schoolData, setSchoolData] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState("-1");
  const [selectedSchool, setSelectedSchool] = useState("-1");
  const [selectedCount, setSelectedCount] = useState(1);
  const [familyCode, setFamilyCode] = useState("");
  const [familyCodeField, setFamilyCodeField] = useState("");
  const [surveyStatus, setSurveyStatus] = useState("new");
  const [maxCount, setMaxCount] = useState(10);
  const [participantUuid, setParticipantUUID] = useState(crypto.randomUUID());
  const [childrenUUIDs, setChildrenUUIDs] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();


  useEffect(() => {
    let _participantUuid = localStorage.getItem("uuid");
    if(_participantUuid){
      setParticipantUUID(_participantUuid);
    }
    else{
      _participantUuid = participantUuid;
      localStorage.setItem("uuid", _participantUuid);
    }

    const _fetchData = async () => {
      setIsLoading(true);
      const sd = await getSchools();
      if(sd.data){
        setSchoolData(sd.data);
        const ud = await getUser('p', _participantUuid);
        setIsLoading(false);
        if(ud.data){
          if(ud.status)
            setSurveyStatus(ud.data.status)
          setChildrenUUIDs(ud.data.children.map(c => {
              return {
                  school: ud.data.school ?? "-1", 
                  age: 0,
                  ...c
                }
          }));
          setSelectedCount(ud.data.children.filter(c => c?.age>0)?.length || 1)
          if(ud.data?.school && ud.data?.school != "null"){
            setSelectedSchool(ud.data.school);
            let tmp = sd.data.find(s => s.id == ud.data.school);
            if(tmp)
              setSelectedDistrict(tmp.filter_one)
          }
          if(ud.data?.familyCode && ud.data?.familyCode != "null"){
            setFamilyCode(ud.data.familyCode);
            setFamilyCodeField(ud.data.familyCode);
          }
        }
      }
    }

    _fetchData()
    .catch((error) => {
      console.error(error);
      setIsLoading(false);
    });    

  }, [maxCount])

  const saveUserData = (d) => {
    if(selectedSchool=="-1")
      return;
    d = (!d || !(d instanceof Object) || d?.nativeEvent) ? {} : d;
    const ud = {
      uuid: participantUuid,
      school: selectedSchool,
      familyCode,
      type: 'p',
      children: childrenUUIDs,
      ...d
    }
    setIsLoading(true);
    saveUser(ud)
    .finally(() => {
      setIsLoading(false);
    })
  }

  const saveChildData = (child) => {
    // const tmp = childrenUUIDs.find(c => c.uuid && c.uuid == uuid);
    // if(!tmp)
    //   return;

    if(!child.uuid || !child.age || child.school == "-1")
      return;

    const ud = {
      uuid: child.uuid,
      parentUuid: participantUuid,
      familyCode: familyCode.length == 8 ? familyCode : "",
      type: 's',
      school: child.school ?? selectedSchool,
      ...child
    }
    setIsLoading(true);
    saveUser(ud)
    .finally(() => {
      setIsLoading(false);
    })
  }

  const setParentSchool = (s) => {
    setSelectedSchool(s);
    setChildrenUUIDs(childrenUUIDs.map(c => {
      let tmp = {...c, school: c.school == "-1" ? s : c.school}
      saveChildData(tmp);
      return tmp;
  }));    
  }

  // useEffect(() => {
  //   saveUserData();
  // }, [selectedSchool, childrenUUIDs])

  
  const emptySelect = { value: "-1", content: "-- nichts ausgewählt --" };


  const getDistricts = (schools) => {
    let districts = [emptySelect];
    if (!schools?.length)
      return districts;
    schools.forEach(s => {
      if (s.filter_one && !districts.find(({ value }) => value == s.filter_one))
        districts.push({ value: s.filter_one, content: s.filter_one == "AS" ? "Ausland" : s.land });
    });
    return districts;
  }

  const getSchoolsBySelectedDistrict = (schools) => {
    if (!schools?.length)
      return [emptySelect];
    return [emptySelect].concat(schools.filter(s => {
      return s.filter_one == selectedDistrict;
    })
      .map(s => {
        return { value: s.id, content: s.school }
      }));
  }

  const setDistrict = (d) => {
    setSelectedDistrict(d);
    setSelectedSchool("-1");
  }

  const updateChild = (child, index) => {
    if(child?.school == "-1")
      child.school = selectedSchool;
    setChildrenUUIDs(childrenUUIDs.map((c,i) => {
      if(i == index){
        let tmp = {
          ...c,
          ...child
        }
        saveChildData(tmp);
        return tmp;
      }
      return c;
    }));
  }

  const startSurvey = (uuid, child) => {
    if(typeof child == "undefined")
      navigate(`/qr/${uuid}`)
    else
      navigate(`/qr/${uuid}/${child}`)
  }


  const canStartParentSurvey = () => {
    let tmp = childrenUUIDs.slice(0,selectedCount).find(c => !c.age || c.school == "-1");
    return selectedSchool!="-1" && !tmp     
  }  

  const renderChildTable = () => {
    return childrenUUIDs.slice(0,selectedCount).map((v,i) => {
      return <div key={i} className="flex gap-2 items-top xs:flex-wrap  md:flex-nowrap">          
                <div className="flex flex-col xs:flex-1  md:flex-auto md:max-w-24">
                  <input className="font-bold dsb" type="number" min={6} max={10} id={`child-${i}`} placeholder={`Kind ${i+1}`} disabled />
                  <label className="dsb" htmlFor={`age-${i}`}>&nbsp;</label>
                </div>
                <div className="flex flex-col xs:flex-1 md:flex-auto md:max-w-24">
                  <input className="dsb text-center" placeholder="Alter (Jahre)" type="number" min={6} max={15} id={`age-${i}`} onChange={(e) => updateChild({age: e.target.value}, i)} value={childrenUUIDs[i]?.age} />
                  <label className="dsb" htmlFor={`age-${i}`}>Alter (Jahre)</label>
                </div>
                <div className="flex flex-1 flex-col">
                  <TailwindSelect className={"bg-white font-mono font-bold text-sm text-blue-800"} id={`school-${i}`} 
                    value={(childrenUUIDs[i]?.school && childrenUUIDs[i]?.school!="-1") ? childrenUUIDs[i]?.school : selectedSchool} 
                    onChange={v => updateChild({school: v.id}, i)}  
                    options={getSchoolsBySelectedDistrict(schoolData)
                      // .filter(v => v.value!="-1")
                      .map((d, i) => {return {id: d.value, name: d.content}})
                    } 
                  />                  
                  <label className="dsb" htmlFor={`school-${i}`}>Schule</label>
                </div>
                <div className="flex flex-col xs:flex-1 md:flex-auto md:max-w-36">
                  <button className="dsb w-full" disabled={!v.age || !v.school || v.school=="-1" || v.status=="completed"} onClick={() => startSurvey(v.uuid)} type="button">
                    {(!v.status || v.status == "new") && <span>starten</span>}
                    {(v.status == "started") && <span>fortsetzen</span>}
                    {(v.status == "completed") && <span>abgeschlossen</span>}
                  </button>
                  <label className="dsb">Fragebogen (Kind)</label>
                </div>                
                <div className="flex flex-col xs:flex-1 md:flex-auto md:max-w-36">
                  <button className="dsb w-full" disabled={!v.age || !v.school || v.school=="-1" || v.statusParent=="completed"} onClick={() => startSurvey(participantUuid, i)} type="button">
                    {(!v.statusParent || v.statusParent == "new") && <span>starten</span>}
                    {(v.statusParent == "started") && <span>fortsetzen</span>}
                    {(v.statusParent == "completed") && <span>abgeschlossen</span>}
                  </button>
                  <label className="dsb">Fragebogen (Eltern)</label>
                </div>                
            </div>
    });
  }

  return (
    <>
      {isLoading && <div className="absolute left-1/2 top-1/2"><ClockLoader color="#36d7b7" /></div>}
      <form>
        <div className="flex flex-col gap-y-4">
          <div>
            <h2 className="mb-4">Bitte wählen Sie eine Schule aus:</h2>
            <div className="flex flex-wrap gap-4">
              <div className="flex flex-col min-w-64 xs:flex-1 md:flex-initial">
                <TailwindSelect id="school-district" className={"bg-accent1-lighter font-mono font-bold text-sm text-blue-800"} value={selectedDistrict} onChange={v => setDistrict(v.id)} onBlur={saveUserData} options={getDistricts(schoolData).map((d, i) => {return {id: d.value, name: d.content}})} />
                <label className="dsb" htmlFor="school-district">Bundesland</label>
              </div>
              <div className="flex flex-col min-w-72 xs:flex-1">
                <TailwindSelect id="school" className={"bg-accent1-lighter font-mono font-bold text-sm text-blue-800"} value={selectedSchool} onChange={v => setParentSchool(v.id)} onBlur={saveUserData} options={getSchoolsBySelectedDistrict(schoolData).map((d, i) => {return {id: d.value, name: d.content}})} />
                <label className="dsb" htmlFor="school">Schule</label>
              </div>
            </div>
          </div>
          <div>
            <h2 className="my-4">Wie viele Ihrer Kinder gehen auf eine Schulpreis-Schule?</h2>
            <div className="flex flex-col">
              <select className="dsb bg-accent1-lighter" onChange={e => setSelectedCount(e.target.value)} value={selectedCount}  id="n-children">
                {Array.from({length: maxCount}, (v,i) => i+1).map((d, i) => <option key={i} value={d}>{d}</option>)}
              </select>              
              {/* <input type="number" max={10} min={1}  onChange={e => setSelectedCount(e.target.value)} onBlur={ e => saveUserData()} value={selectedCount}  id="n-children" /> */}
            </div>
          </div>
          <div>
            <h2 className="my-4">Familien-Code</h2>
            <div className="flex flex-col">
              <InputFamilyCode className={"bg-accent1-lighter"} value={familyCodeField} onChange={v => {setFamilyCode(v); if(v.length == 8) saveUserData({familyCode: v});}} />
            </div>
          </div>

          <div>
            <h2 className="my-4">Fragebögen:</h2>
            <div className="flex flex-col xs:gap-y-8 md:gap-y-4 p-4 bg-gray-200">
              {renderChildTable()}
            </div>
            {/* <div className="flex gap-x-2 items-center mt-4 p-4 bg-gray-200">
              <div className="flex-1">
                <button className="dsb w-full" disabled={surveyStatus=="complete" || !canStartParentSurvey()} onClick={() => startSurvey(participantUuid)} type="button">
                  <span>Elternfragebogen </span>
                  {(surveyStatus == "new") && <span>starten</span>}
                  {(surveyStatus == "started") && <span>fortsetzen</span>}
                  {(surveyStatus == "complete") && <span>abgeschlossen</span>}
                </button>
              </div>
            </div> */}
          </div>

        </div>
      </form>
    </>
  )
}

export default PageEltern
