import { axiosClient, axiosConfig } from "../apiClient";
import useAxios, { configure } from 'axios-hooks'

//   const cache = new LRU({ max: 10 })
// configure({ axiosClient, cache })

export function getUserById(uuid){
    return axiosClient.get(`/user/${uuid}`);
}

export function useGetUserById(uuid){
    return useAxios({
        url: `/user/${uuid}`,
        ...axiosConfig
    })
}

export function getUser(type,uuid){
    return axiosClient.get(`/user/${type}/${uuid}`);
}

export function useGetUser(type,uuid){
    return useAxios({
        url: `/user/${type}/${uuid}`,
        ...axiosConfig
    })
}

export function saveUser(ud){
    return axiosClient.post(`/user`, ud);
}

export function storeresult(ud, surveyResult, lastPageNo, change, childIndex){
    return axiosClient.post(`/storeResult`, {uuid: ud.uuid, user: ud, result: surveyResult, lastPageNo, change, childIndex});
}