import axios from 'axios';
// import { configure } from 'axios-hooks'

const axiosConfig = {
    baseURL: `${import.meta.env.VITE_APP_APIURL}`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
}

const axiosClient = axios.create(axiosConfig);

axiosClient.interceptors.response.use(
    function (response) {
      return response;
    }, 
    function (error) {
      let res = error.response;
    //   if (res.status == 401) {
    //     window.location.href = “https://example.com/login”;
    //   }
      console.error(`error fetching data: ${res}`);
      return Promise.reject(error);
    }
  );

//   const cache = new LRU({ max: 10 })
// configure({ axios, cache })
// configure({ axiosClient });

export  {
    axiosClient,
    axiosConfig
};
    



// fetch(`${import.meta.env.VITE_APP_APIURL}/survey/${type}/${uuid}`)
// .then((response) => {
//     if(!response.ok)
//         throw new Error("error fetching data");
//     return response.json();
// })
// .then(json => {
//     if(json && json.surveyJs)
//         setSurvey(new Model(json.surveyJs));                
//     setLoading(false);
// })

// axios.get(`${import.meta.env.VITE_APP_APIURL}/survey/${type}/${uuid}`)
// .then(response => {
//     if(response && response.data?.surveyJs)
//         setSurvey(new Model(response.data?.surveyJs));                
// })
// .catch(function (error) {
//     // handle error
//     console.error(error);
// })
// .finally(function () {
//     setLoading(false);
//     // always executed
// });