import { Model, FunctionFactory } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { ClockLoader } from "react-spinners"

import 'survey-core/defaultV2.min.css';
import { useEffect, useState } from 'react';
import { getSurvey, useGetSurvey } from '../api/lib/survey';

import { themeJson } from '../assets/surveytheme';
import Showdown from 'showdown';
import { useNavigate, useParams } from 'react-router-dom';
import { getUser, getUserById, saveUser, storeresult } from '../api/lib/participants';



function PageSurvey() {

    const {uuid, child} = useParams();
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(true);
    // const [userData, setUserData] = useState({});
    const [hasError, setError] = useState(false);
    const [survey, setSurvey] = useState(null);

    let userData = {};

    // const [{ data, loading, error }, refetch] = useGetSurvey(type,uuid);

    const initSurvey = (json) => {

        function validateSoftPrompt (params) {
    
            const targetResponse = params[0];
            const targetQuestion = params[1];
            const softPrompt = params[2];
        
            console.log("Generic Soft Prompt Helper: " + targetQuestion+  "/" + softPrompt);
         
            var nr = survey.getQuestionByName(softPrompt);
                    
            if (targetResponse == null){
                nr.setPropertyValue('visible', true);
            } else {
                nr.setPropertyValue('visible', false);
                return true;
            }   
        
            if (nr.value.length>0){
                return true;
            }
            return false;
          }
        
         
        FunctionFactory.Instance.register("validateSoftPrompt", validateSoftPrompt);
        // Survey.settings.maxWidth="1366px";
        
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('showToc')){
            json["showTOC"] = true;
        }
        if (searchParams.has('showPreview')){
            json["showPreviewBeforeComplete"] = 'showAllQuestions';
        }
        
        const surveyModel = new Model(json);
        surveyModel.applyTheme(themeJson);
        
        const converter = new Showdown.Converter();
        surveyModel.onTextMarkdown.add(function (surveyModel, options) {
            let str = converter.makeHtml(options.text);
            // str = str.substring(3);
            // str = str.substring(0, str.length - 4);
            options.html = str;
        });
        
        surveyModel.onComplete.add((sender, options) => {
            storeresult({uuid, status: "completed"}, sender.data,  sender.currentPageNo, {name: options.name, value: options.value}, child);
            if(userData.type && userData.type == 'p' || typeof child != "undefined")
                navigate("/eltern");
            else if(userData.type)
                navigate("/start");
        });
        surveyModel.onValueChanged.add((sender, options) => {
            storeresult({uuid, status: "started"}, sender.data,  sender.currentPageNo, {name: options.name, value: options.value}, child);
        });

        return surveyModel;
    }

    const fetchUser = () => {
        return getUserById(uuid)
        .then(response => {
            if(response && response.data){
                // setUserData(response.data);
                userData = response.data;
            }            
        })
        .catch (error => {
            console.error(error);
            setError(true);
        });        
    }

    const fetchSurvey = () => {
        return getSurvey(uuid, child)
        .then(response => {
            if(response && response.data?.surveyJs){
                const surveyModel = initSurvey(response.data?.surveyJs);
                if(!!response.data?.result){
                    surveyModel.data = response.data?.result;
                    if(!!response.data?.lastPageNo)
                        surveyModel.currentPageNo = (typeof response.data?.lastPageNo === "number") ? response.data?.lastPageNo : 0;
                }

                setSurvey(surveyModel);
            }            
        })
        .catch (error => {
            console.error(error);
            setError(true);
        });       
    }



    useEffect(() => {
        Promise.all([fetchUser(), fetchSurvey()])        
        .finally(() => {
            setLoading(false);
        })
    }, [uuid]);

    return <>
        {!isLoading && !hasError && !!survey && <Survey model={survey} />}
        {/* {!loading && !error && !!data && <Survey model={new Model(data)} />} */}
        {/* {!loading && !error && <p>{JSON.stringify(data)}</p>} */}
        {isLoading && <ClockLoader className="absolute left-1/2 top-1/2" color="#36d7b7" />}
        {hasError && <p>error fetching data</p>}
    </>;
}

export default PageSurvey
