import { axiosClient, axiosConfig } from "../apiClient";
import useAxios, { configure } from 'axios-hooks'

//   const cache = new LRU({ max: 10 })
// configure({ axiosClient, cache })

export function getSchools(){
    return axiosClient.get(`/schools`);
}

export function useGetSchools(){
    return useAxios({
        url: `/schools`,
        ...axiosConfig
    })
}



