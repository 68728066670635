import { axiosClient, axiosConfig } from "../apiClient";
import useAxios, { configure } from 'axios-hooks'

//   const cache = new LRU({ max: 10 })
// configure({ axiosClient, cache })

export function getSurvey(uuid, child){
    return axiosClient.get(`/survey/${uuid}${child ? `/${child}` : ''}`);
}

export function useGetSurvey(uuid){
    return useAxios({
        url: `/survey/${uuid}`,
        ...axiosConfig
    })
}



