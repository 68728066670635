import { Link, useNavigate, useParams } from "react-router-dom"
import QRCode from "react-qr-code";
import { useEffect } from "react";
import { useGetUserById } from "../api/lib/participants";


const getSurveyURL = (uuid, child) => {
    return `${import.meta.env.VITE_APP_BASEURL}/survey/${uuid}${child ? `/${child}` : '/0'}`;
}

function SurveyLandingParents({uuid, child}) {
    const url = getSurveyURL(uuid, child);
    return(
        <>
            <Link to={"/eltern"}><button className="dsb">Zurück</button></Link>
            <h2>Eltern</h2>
            <h3 className="font-bold">
                {uuid}
            </h3>
            <p className="font-mono">
                <a href={url} target="_blank">{url}</a>
            </p>
            <div className="bg-white p-4">
                <QRCode value={url} />
            </div>            
        </>
    )
}
function SurveyLandingStudents({uuid}) {
    const url = getSurveyURL(uuid);
    return(
        <>
            <Link to={"/start"}><button className="dsb">Zurück</button></Link>        
            <h2>Schueler</h2>
            <h3 className="font-bold">
                {uuid}
            </h3>
            <p className="font-mono">
                <a href={url} target="_blank">{url}</a>
            </p>
            <div className="bg-white p-4">
                <QRCode value={url} />
            </div> 
        </>
    )
}
function SurveyLandingChildren({uuid}) {
    const url = getSurveyURL(uuid);
    return(
        <>
            <Link to={"/eltern"}><button className="dsb">Zurück</button></Link>
            <h2>Schueler u16</h2>
            <h3 className="font-bold">
                {uuid}
            </h3>
            <p className="font-mono">
                <a href={url} target="_blank">{url}</a>
            </p>
            <div className="bg-white p-4">
                <QRCode value={url} />
            </div>            
        </>
    )
}

function SurveyLanding() {

    const {uuid, child} = useParams();

    // const navigate = useNavigate();

    const [{ data, loading, error }, refetch] = useGetUserById(uuid);

    return (
        <>
            {error && <p>invalid uuid</p>}
            {(data && data.type == "s" && !!data.parentUuid) && <SurveyLandingChildren uuid={uuid} />}
            {(data && data.type == "s" && !data.parentUuid) && <SurveyLandingStudents uuid={uuid} />}
            {(data && data.type == "p") && <SurveyLandingParents uuid={uuid} child={child} />}
        </>
    )
}

export default SurveyLanding
