import React, { useState, useRef, useEffect } from 'react';
import { Tooltip } from 'react-tooltip'

const InputFamilyCode = ({onChange, value, className}) => {

    const [motherInitials, setMotherInitials] = useState('');
    const [houseNumber, setHouseNumber] = useState('');
    const [motherBirthday, setMotherBirthday] = useState('');
    const [childInitials, setChildInitials] = useState('');
    const [childBirthMonth, setChildBirthMonth] = useState('');

    const motherInitialsRef = useRef();
    const houseNumberRef = useRef();
    const motherBirthdayRef = useRef();
    const childInitialsRef = useRef();
    const childBirthMonthRef = useRef();

    const tooltips = {
        motherInitials: 'Die beiden ersten Buchstaben des Vornamens der Mutter (z.B. UR für Ursula)',
        houseNumber: 'Die erste Ziffer der Hausnummer der Anschrift der Mutter (z.B. 1 für 13)',
        motherBirthday: 'Der Geburtstag der Mutter (nur der Tag, wenn einstellig dann mit führender Null, z.B. 03 für den 03. März 1965)',
        childInitials: 'Die beiden ersten Buchstaben des Vornamens des jüngsten Kindes (z.B. MA für Max)',
        childBirthMonth: 'Den ersten Buchstaben des Geburtsmonats des jüngsten Kindes (z.B. J für Juli)'
    }; 

    const getFamilyCode = () => {
        return `${motherInitials}${houseNumber}${motherBirthday}${childInitials}${childBirthMonth}`;
    }

    const handleMotherInitialsChange = (e) => {
        const input = e.target.value;
        if (/^[a-zA-Z]*$/.test(input)) {
            setMotherInitials(input.toUpperCase());
        }
        if(e.target.selectionStart === 2)
            houseNumberRef.current.focus();        
    };

    const handleHouseNumberChange = (e) => {
        const input = e.target.value;
        if (/^[0-9]*$/.test(input)) {
            setHouseNumber(input);          
        }
        if(e.target.selectionStart === 1)
            motherBirthdayRef.current.focus();         
    };

    const handleMotherBirthdayChange = (e) => {
        const input = e.target.value;
        if (/^[0-9]*$/.test(input)) {
            setMotherBirthday(input);          
        }
        if(e.target.selectionStart === 2)
            childInitialsRef.current.focus();         

    };

    const handleChildInitialsChange = (e) => {
        const input = e.target.value;
        if (/^[a-zA-Z]*$/.test(input)) {
            setChildInitials(input.toUpperCase())
        }
        if(e.target.selectionStart === 2)
            childBirthMonthRef.current.focus();          
    };

    const handleChildBirthMonthChange = (e) => {
        const input = e.target.value;
        if (/^[a-zA-Z]*$/.test(input)) {
            setChildBirthMonth(input.toUpperCase());
        }
    };

    const handleBackspace = (e, ref) => {
        if ((e.key === 'Backspace' || e.keyCode === 37) && e.target.selectionStart === 0) {
            ref.current.focus();
        }
    };

    useEffect(() => {
        if(value && value.length === 8){
            setMotherInitials(value.substring(0, 2));
            setHouseNumber(value.substring(2, 3));
            setMotherBirthday(value.substring(3, 5));
            setChildInitials(value.substring(5, 7));
            setChildBirthMonth(value.substring(7));
        }
    }, [value]);

    useEffect(() => {
        if(typeof onChange === 'function')
            onChange(getFamilyCode());
    }, [motherInitials, houseNumber, motherBirthday, childInitials, childBirthMonth]);

    return (
        <div className={`flex gap-2 w-full max-w-full lg:w-auto`}>
            <input
                ref={motherInitialsRef}
                type="text"
                value={motherInitials}
                onChange={handleMotherInitialsChange}                
                maxLength={2}
                data-tooltip-id="motherInitials"
                data-tooltip-content={tooltips.motherInitials}
                data-tooltip-place="top"
                className={`lg:w-12 lg:flex-none w-1/5 flex-1 ${className ?? ''}`}
                autoComplete='off'
                
            />
            <Tooltip className="custom-tooltip" id="motherInitials" />
            <input
                ref={houseNumberRef}
                type="text"
                value={houseNumber}
                onChange={handleHouseNumberChange}
                onKeyDown={(e) => handleBackspace(e, motherInitialsRef)}
                maxLength={1}
                data-tooltip-id="houseNumber"
                data-tooltip-content={tooltips.houseNumber}
                className={`lg:w-12 lg:flex-none w-1/5 flex-1 ${className ?? ''}`}
                autoComplete='off'
            />
            <Tooltip placement='top' className="custom-tooltip" id="houseNumber" />
            <input
                ref={motherBirthdayRef}
                type="text"
                value={motherBirthday}
                onChange={handleMotherBirthdayChange}
                onKeyDown={(e) => handleBackspace(e, houseNumberRef)}
                maxLength={2}
                data-tooltip-id="motherBirthday"
                data-tooltip-content={tooltips.motherBirthday}
                onBlur={(e) => {if(e.target.value.length === 1) e.target.value = e.target.value.padStart(2, '0')}}
                className={`lg:w-12 lg:flex-none w-1/5 flex-1 ${className ?? ''}`}
                autoComplete='off'
            />
            <Tooltip placement='top' className="custom-tooltip" id="motherBirthday" />
            <input
                ref={childInitialsRef}
                type="text"
                value={childInitials}
                onChange={handleChildInitialsChange}
                onKeyDown={(e) => handleBackspace(e, motherBirthdayRef)}
                maxLength={2}
                data-tooltip-id="childInitials"
                data-tooltip-content={tooltips.childInitials}
                className={`lg:w-12 lg:flex-none w-1/5 flex-1 ${className ?? ''}`}
                autoComplete='off'
            />
            <Tooltip placement='top' className="custom-tooltip" id="childInitials" />
            <input
                ref={childBirthMonthRef}
                type="text"
                value={childBirthMonth}
                onChange={handleChildBirthMonthChange}
                onKeyDown={(e) => handleBackspace(e, childInitialsRef)}
                maxLength={1}
                data-tooltip-id="childBirthMonth"
                data-tooltip-content={tooltips.childBirthMonth}
                className={`lg:w-12 lg:flex-none w-1/5 flex-1 ${className ?? ''}`}
                autoComplete='off'
            />
            <Tooltip placement='top' className="custom-tooltip" id="childBirthMonth" />
        </div>
    );
};

export default InputFamilyCode;