import { Outlet } from "react-router-dom"
import DSBFooter from "../components/footer"
import DSBHeader from "../components/header"

function LayoutSurvey() {
  return (
    <>
        <main className="flex flex-col min-h-screen w-full">
            {/* <DSBHeader /> */}
            <section className="flex-1 relative py-8">
                <Outlet />
            </section>
            <DSBFooter />
        </main>
    </>
  )
}

export default LayoutSurvey
