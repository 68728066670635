import React from 'react'
import ReactDOM from 'react-dom/client'
// import {
//   createBrowserRouter,
//   BrowserRouter,
//   RouterProvider,
//   Route,
//   Link,
//   Router,
//   Routes,
// } from "react-router-dom"

import App from './App.jsx'
import './index.css'
// import LayoutRoot from './layout/root.jsx'

/*
const router = createBrowserRouter([
  {
    path: "/",
    element: <LayoutRoot />,
    // children: [
    //   {
    //     path: "contact",
    //     element: <Contact />,
    //   },
    // ]    
  },
]);
*/

// ReactDOM.createRoot(document.getElementById("root")).render(
//   <React.StrictMode>
//         <Router>
//             <Routes>
//                 <Route path='/' element={<LayoutRoot />} />
//             </Routes>
//         </Router>
//   </React.StrictMode>,  
// );


// ReactDOM.createRoot(document.getElementById("root")).render(
//   <React.StrictMode>
//     <RouterProvider router={router} />
//   </React.StrictMode>,  
// );


ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

