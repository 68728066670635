import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import useWindowDimensions from "../hooks/useWindowDimensions";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function TailwindSelect({ options, value, onChange, onBlur, className }) {

    const opts = Array.isArray(options) ? options : [{ id: 0, name: "--" }];
    const [selected, setSelected] = useState(opts[0]);
    const {width, height} = useWindowDimensions();
    

    const triggerOnChange = (value) => {
        let tmp = opts.find(o => o.id == value);
        if(!!tmp)
            setSelected(tmp);
        if (typeof onChange == "function")
            onChange(value);
    }

    const triggerOnBlur = () => {
        if (typeof onBlur == "function")
            onBlur(value);
    }

    useEffect(() => {
        let tmp = opts.find(o => o.id == value);
        if(!!tmp)
            setSelected(tmp);
    }, [value])

    const truncate = (str) => {
        if(width<=360)
            return str.substring(0,40) + "...";
        return str;
    }

    return (
        <Listbox value={selected} onChange={triggerOnChange}>
            {({ open }) => (
                <>
                    {/*
                    <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                        Assigned to
                    </Listbox.Label> 
                    */}
                    <div className={`relative max-w-full ${className ?? ''}`}>
                        <Listbox.Button className="relative w-full cursor-default bg-inherit py-2 pl-3 pr-10 text-left text-gray-900 shadow-sm border border-gray-500 focus:outline-none focus:border-accent1 xs:text-xs sm:text-sm">
                            <span className="block truncate">{truncate(selected?.name)}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options onBlur={e => triggerOnBlur()} className="absolute z-10 mt-1 max-h-60 w-full overflow-auto bg-inherit py-1 text-base shadow-lg border-1 border-black border-opacity-5 focus:outline-none xs:text-xs  sm:text-sm">
                                {opts.map((obj) => (
                                    <Listbox.Option
                                        key={obj.id}
                                        className={({ active }) =>
                                            classNames(
                                                active ? "bg-accent1 text-white" : "text-gray-900",
                                                "relative cursor-default select-none py-2 pl-3 pr-9"
                                            )
                                        }
                                        value={obj}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={classNames(
                                                        selected || obj.id == value ? "font-semibold" : "font-normal",
                                                        "block truncate"
                                                    )}
                                                >
                                                    {obj.name}
                                                </span>

                                                {selected || obj.id == value ? (
                                                    <span
                                                        className={classNames(
                                                            active ? "text-white" : "text-accent1",
                                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    );
}
