import { Outlet } from "react-router-dom"

function PageLanding() {
  return (
    <>
        <h1 className="font-bold">
            Hello world!
        </h1>
    </>
  )
}

export default PageLanding
